exports.interopDefault = function(a) {
  return a && a.__esModule ? a : {default: a};
};

exports.defineInteropFlag = function(a) {
  Object.defineProperty(a, '__esModule', {value: true});
};

exports.exportAll = function(source, dest) {
  Object.keys(source).forEach(function(key) {
    if (key === 'default' || key === '__esModule') {
      return;
    }

    // Skip duplicate re-exports when they have the same value.
    if (key in dest && dest[key] === source[key]) {
      return;
    }

    Object.defineProperty(dest, key, {
      enumerable: true,
      get: function() {
        return source[key];
      },
    });
  });

  return dest;
};

exports.export = function(dest, destName, get) {
  Object.defineProperty(dest, destName, {
    enumerable: true,
    get: get,
  });
};
