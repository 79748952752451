import './loader.scss';

var GLOBAL_STORE = {};

export class HapimapLoader {
	constructor(selector){
		this.selector = selector;
		this.domTarget = document.querySelector(this.selector)
		this.eventCallbacks = {};
		this.status = null;

		// try to get an existing instance at first
		this.hapimap = HapimapLoader.getInstance(this.selector);
		if(this.hapimap)
		{
			// micro defered time (map instance allready rock'n loaded)
			setTimeout(this.loaded.bind(this),1);
		}
		else
		{
			this.buildLoadingScreen();
			// defered start - keep browser light on startup
			setTimeout(this.load.bind(this),250);
		}
	}

	async load()
	{
		if(this.status === 'loading' )
		{
			return ;
		}

		this.status = 'loading';
		if(GLOBAL_STORE[this.selector])
		{
			this.hapimap = GLOBAL_STORE[this.selector]
		}
		else
		{
			this.hapimap = new (await import('./index.js')).default(this.domTarget)
			this.hapimap.GLOBAL_STORE_REF = this.selector;
			GLOBAL_STORE[this.selector] = this.hapimap;
		}

		this.status = 'ready'
		this.loaded()
		return ;
	}

	onLoad(callback) {
		this.eventCallbacks.load = callback;
		// Available events type : [load]
	}
	loaded() {
		if(typeof this.eventCallbacks.load === "function")
			this.eventCallbacks.load(this.hapimap);
	}

	static getStore()
	{
		return GLOBAL_STORE;
	}
	static getInstance(selector)
	{
		return GLOBAL_STORE[selector] || undefined;
	}
	static reloadAll()
	{
		for(let i in GLOBAL_STORE)
		{
			GLOBAL_STORE[i].reload();
		}
	}
	static resetAll()
	{
		for(let i in GLOBAL_STORE)
		{
			GLOBAL_STORE[i].reset();
		}
	}
	static deleteInstance(selector)
	{
		selector = selector && selector.GLOBAL_STORE_REF ? selector.GLOBAL_STORE_REF : selector;
		delete GLOBAL_STORE[selector];
	}
	static dismountAll()
	{
		for(let i in GLOBAL_STORE)
		{
			GLOBAL_STORE[i].dismount();
		}
	}

	buildLoadingScreen()
	{
		const svg = require('url:./loader.svg');
		this.domTarget.innerHTML = ``
			+`<div class="hapimap--container">`
				+`<div class="hapimap--loader">`
					+`<div class="protected content bg-indigo">`
						+`<div class="container text-center no-reveal">`
							+`<div class="box">`
								+`<img src="${svg}">`
								+`<div class="title load">Loading</div>`
								+`<div class="title init">Building</div>`
								+`<div class="credit">Hapimap</div>`
								+`<div class="wave -one"></div>`
								+`<div class="wave -two"></div>`
								+`<div class="wave -three"></div>`
							+`</div>`
						+`</div>`
					+`</div>`
				+`</div>`
			+`</div>`;
	}
}
export default HapimapLoader
